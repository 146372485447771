import DriverRequest from '@requests/driver_request';
import CategoryRequest from '@requests/category_request';
import OperationRequest from '@requests/operation_request';

export default {
    getEntities({ commit }, filter) {
        DriverRequest.index({...filter, state: null})
            .then((data) => {
                commit('SET_ENTITIES', data);
            });
    },
    store(_, payload) {
        return DriverRequest.store(payload);
    },
    update(_, entity) {
        return DriverRequest.update(entity.id, entity);
    },
    show(_, id) {
        return DriverRequest.show(id);
    },
    destroy(_, entity) {
        return DriverRequest.destroy(entity.id);
    },
    getCategories() {
        return CategoryRequest.index();
    },
    getOperations({ commit }) {
        OperationRequest.index().then(res => {
            commit('SET_OPERATIONS', res)
        });
    }
}